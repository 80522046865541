import * as React from 'react';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import automatizacijaIzgovora from '../../../images/educational-corner/blog34/automatizacija-izgovora.jpg';
import diferencijacijaGlasova from '../../../images/educational-corner/blog34/diferencijacija-glasova.jpg';

const Dyslalia = () => {
  return (
    <PageNew
      title="KOKOLINGO - Dislalija: Poremećaj izgovora glasova i kako ga korigirati"
      description="Dislalija, poznata i kao artikulacijski poremećaj ili poremećaj 
      izgovora glasova, odnosi se na nepravilan izgovor pojedinih glasova kod djece i 
      odraslih osoba. Osobe s dislalijom mogu imati poteškoće s izgovorom određenih glasova, 
      zamjenom jednog glasom drugim ili pogrešnim izgovorom glasova."
      headline="Dislalija: Poremećaj izgovora glasova i kako ga korigirati"
    >
      <Text as="p">
        Dislalija, poznata i kao artikulacijski poremećaj ili poremećaj izgovora
        glasova, odnosi se na nepravilan izgovor pojedinih glasova kod djece i
        odraslih osoba. Osobe s dislalijom mogu imati poteškoće s izgovorom
        određenih glasova, zamjenom jednog glasom drugim ili pogrešnim izgovorom
        glasova. Dakle, moguće je da se određeni glas <b>ne izgovara</b> (npr.
        ruka = uka), da se <b>mijenja</b> drugim glasom (npr. kapa = tapa) ili
        da se <b>izgovara pogrešno</b> (npr. izgovaranje glasa R „grleno“).
      </Text>
      <Text as="p">
        Istovremeno, podrazumijeva se da osobe imaju:
        <ul>
          <li>uredan sluh - nemaju oštećenje sluha, ne nose slušni aparatić</li>
          <li>urednu inteligenciju - prosječan ili iznadprosječan IQ</li>
          <li>
            bez neuroloških oštećenja - bez krvarenja u mozgu pri porodu ili
            moždanog udara
          </li>
          <li>
            uredni jezični profil - imaju uredno razvijen rječnik, govore
            gramatički ispravnim rečenicama
          </li>
        </ul>
      </Text>
      <Text as="h2">Vrste dislalija i njihovi uzroci</Text>
      <Text as="p">
        Dislalije mogu zahvaćati više glasova iz različitih artikulacijskih
        skupina (npr. glasove R i S) ili nekoliko glasova iz iste artikulacijske
        skupine (npr. K, G i H).
      </Text>
      <Text as="p">
        Ovisno o uzroku, dislalije se dijele na organske i funkcionalne.
        Organske dislalije uključuju nepravilnosti zuba, zagriza, visoko i usko
        tvrdo nepce te prekratku podjezičnu vezicu. Funkcionalne dislalije
        nastaju zbog nedovoljne pokretljivosti govornih organa ili slabijeg
        razvijenog fonematskog sluha. Fonematski sluh je vještina koja nam
        omogućava da čujemo od kojih se glasova pojedina riječ sastoji. (više o
        tome u članku{' '}
        <a href="https://www.kokolingo.hr/edukativni-kutak/artikulacijski-i-fonoloski-poremecaj">
          <b>Artikulacijski i fonološki poremećaj</b>
        </a>
        ).
      </Text>
      <Text as="p">
        Vrste dislalija su:
        <ul>
          <li>
            sigmatizam – neispavan izgovor glasova C, S, Z, Č, Ć, DŽ, Đ, Š, Ž
          </li>
          <li>lambdacizam - neispavan izgovor glasova L i LJ</li>
          <li>rotacizam - neispavan izgovor glasa R</li>
          <li>
            kapacizam, gamacizam i hitizam - neispavan izgovor glasova K, G i H
          </li>
          <li>etacizam - neispavan izgovor glasa E</li>
        </ul>
      </Text>
      <Text as="h2">Logopedska terapija: Faze korekcije dislalije</Text>
      <Text as="p">
        Najvažnije načelo korekcije dislalije je{' '}
        <b>sustavnost i stupnjevitost</b> rada. Logopedska se terapija stoga
        provodi u <b>pet faza</b>:
      </Text>
      <Text as="p">
        <ol>
          <li>
            <b>Pripremna faza</b> - razvijanje fonematskog sluha i priprema
            artikulacijskog aparata za izvođenje glasova. Artikulacijski aparat
            podrazumijeva organe koji sudjeluju u izgovaranju glasova - jezik,
            usne, obrazi, meko nepce.
          </li>
          <li>
            <b>Faza formiranja ispravne artikulacije glasa</b> - postizanje
            ispravne artikulacije oponašanjem, mehaničkim djelovanjem
            (primjerice podizanje vrha jezika špatulom da bi se olakšao izgovor
            glasa L) ili kombinacijom. Ispravnu artikulaciju je potrebno
            podijeliti na jednostavne artikulacijske položaje i pokrete. Nakon
            višekratnog ponavljanja, taj se pokret stabilizira.{' '}
          </li>
          <li>
            <b>Faza automatizacije izgovora glasa</b> - stabilizacija nove
            artikulacije u slogovima, riječima i rečenicama. To se postiže
            pomoću vježbi s posebno odabranim primjerima u kojima se novi glas
            pojavljuje u različitim pozicijama (na početku, u sredini pa na
            kraju) od jednostavnih prema složenijim. Primjerice, ako dijete
            ispravlja izgovor glasa L, prvo će ga vježbati u slogovima (LA, LE,
            ALA, ELI, IL...), potom u riječima (LABUD, KOLA, VAL) i na kraju u
            rečenicama (Lana ima malo pile.).
          </li>
          <BlogImage
            imgSrc={automatizacijaIzgovora}
            imgAlt="Funkcionalna igra"
            imgTitle="Funkcionalna igra"
          />
          <li>
            <b>Faza diferencijacije glasova</b> - rad na suprotstavljanju
            dotičnog glasa s drugim artikulacijski i akustički bliskim glasovima
            (primjerice L i V ili Č i C).
          </li>
          <BlogImage
            imgSrc={diferencijacijaGlasova}
            imgAlt="Funkcionalna igra"
            imgTitle="Funkcionalna igra"
          />
          <li>
            <b>Faza uvođenja glasa u spontani govor</b> - formiranje sposobnosti
            i navika pravilne upotrebe nove artikulacije u svim situacijama
            spontane, prirodne jezično-govorne komunikacije
          </li>
        </ol>
      </Text>
      <Text as="h2">Izazovi u korekciji dislalije</Text>
      <Text as="p">
        Važno je napomenuti da stvaranje novog načina izgovaranja glasa možda
        nije toliko teško, ali pretvoriti ga u automatiziranu, stabilnu naviku
        može biti znatno izazovnije. Zbog toga je posljednja faza korekcije
        često najzahtjevnija, posebno za roditelje koji bi trebali svakodnevno
        podsjećati dijete na pravilan izgovor glasova dok mu to ne postane
        navika.
      </Text>
      <Text as="p">
        Kroz ovaj tekst, ciljali smo na pružanje informacija o dislaliji,
        poremećaju izgovora glasova, i kako ga korigirati. Nadamo se da će vam
        ove informacije pomoći u boljem razumijevanju dislalije i logopedskih
        terapija koje mogu pomoći u korekciji izgovora.
      </Text>
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Posokhova, I. (2005). <i>Izgovor: kako ga poboljšati</i>. Zagreb:
            Ostvarenje
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default Dyslalia;
